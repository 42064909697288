
import DrugIndexListTable from "@/modules/drug-index/components/drug-index-list-table.vue";

// import DrugDialog from '@/modules/drug-index/components/drug-dialog.vue';
import { mapActions } from "vuex";
// import {i18n} from '@/i18n';
// import firebase from 'firebase/app'
// import 'firebase/firestore';
// import AddDialog from '@/modules/services/components/add-dialog.vue';
import ImageBackground from "@/modules/home/components/image-background.vue";
import i18n from "@/vueI18n";
import { AnalyticsManager } from "@/shared/firebase/firebase-analytics";

export default {
  data() {
    return {
      displayTitle: false,
      // imageBg: "/images/drug-index-bg.png",
      // title:this.i18n('drugDeal.services.drugIndex'),
      // subtitle1:this.i18n('drugDeal.drugIndex.searchForDrugs'),
      // subtitle2:this.i18n('drugDeal.drugIndex.name'),
    };
  },
  components: {
    [DrugIndexListTable.name]: DrugIndexListTable,
    // [AddDialog.name]: AddDialog,
    [ImageBackground.name]: ImageBackground,
  },
  methods: {
    ...mapActions({
      setDrugIndex: "home/setDrugIndex",
      //  setSearch: 'drugIndex/list/setSearch'
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    changeHomeHeader(value) {
      // this.$emit('changeHomeHeader',value)
      this.displayTitle = value;
    },
  },
  created() {
    document.title =
      this.i18n("app.title") + " | " + this.i18n("drugDeal.services.drugIndex");
    this.setDrugIndex(true);
    AnalyticsManager.logScreen("View Drug Index Details", "DrugIndexProfile");
  },
  watch: {
    lang() {
      document.title =
        this.i18n("app.title") +
        " | " +
        this.i18n("drugDeal.services.drugIndex");
    },
  },
  computed: {
    lang() {
      // debugger
      return i18n.locale;
    },
    imageBg() {
      return "/images/drug-index-bg.png";
    },
    title() {
      return this.i18n("drugDeal.services.drugIndex");
    },
    subtitle1() {
      return this.i18n("drugDeal.drugIndex.searchForDrugs");
    },
    subtitle2() {
      return this.i18n("drugDeal.drugIndex.name");
    },
  },
};
