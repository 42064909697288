import { render, staticRenderFns } from "./drug-index-list-page.vue?vue&type=template&id=485691be&"
import script from "./drug-index-list-page.vue?vue&type=script&lang=js&"
export * from "./drug-index-list-page.vue?vue&type=script&lang=js&"
import style0 from "./drug-index-list-page.vue?vue&type=style&index=0&id=485691be&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QScrollObserver,QImg,QTab} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollObserver,QImg,QTab})
